import { Box, Button, FormControl, FormLabel, Input, Stack, useColorModeValue, useToast } from "@chakra-ui/react"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { ethers } from "ethers"
import React, { useCallback, useState } from "react"

import { HCAPTCHA_SITEKEY } from "../constant"
import { getFaucet } from "../service/appSync"

export function FaucetDesignTestnet() {
    const toast = useToast()

    const [address, setAddress] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const toastError = useCallback(
        description => {
            toast({
                title: "Error",
                description,
                status: "error",
                duration: 10_000,
                isClosable: true,
            })
        },
        [toast],
    )

    const hcaptchaTheme = useColorModeValue("light", "dark")
    const [hcaptchaPasscode, setHcaptchaPasscode] = useState("")

    const handleSubmit = useCallback(async () => {
        try {
            setIsLoading(true)
            const isAddressValid = ethers.utils.isAddress(address)
            if (!isAddressValid) {
                return toastError("Invalid address")
            }
            const res = await getFaucet(address, hcaptchaPasscode)
            if (!res) {
                return toastError("unknown error")
            }
            if (res.errorMsg) {
                return toastError(res.errorMsg)
            }
            const sentTokens = res.issuingResults
            sentTokens?.forEach(({ token, amount, msg, errorMsg }) => {
                if (errorMsg) {
                    toastError(`${token}: ${errorMsg}`)
                    return
                }
                // NOTE: on success
                toast({
                    title: `${amount} ${token.toUpperCase()}`,
                    description: msg,
                    status: "success",
                    duration: 10_000,
                    isClosable: true,
                })
            })
            // NOTE: reset form
            setAddress("")
            setHcaptchaPasscode("")
        } catch (err: any) {
            // NOTE: for 400, 500 (status code returned from BE)
            if (err.response) {
                return toastError(err.response.data.error)
            }
            // NOTE: for 502, 504 (CloudFront errors), can't get http status code (err.response.status)
            toastError(err.message)
        } finally {
            setIsLoading(false)
        }
    }, [address, hcaptchaPasscode, toast, toastError])

    return (
        <Box rounded={"lg"} bg={useColorModeValue("white", "gray.700")} boxShadow={"lg"} p={8}>
            <Stack spacing={8}>
                <FormControl id="email">
                    <FormLabel>Ethereum address</FormLabel>
                    <Input
                        value={address}
                        onChange={e => setAddress(e.target.value)}
                        placeholder="Enter your wallet address"
                    />
                </FormControl>

                <Stack direction={{ base: "column", sm: "row" }} justify={"space-between"}>
                    <Box>
                        {HCAPTCHA_SITEKEY ? (
                            <HCaptcha
                                onError={err => toastError(`hCaptcha: ${err}`)}
                                onExpire={() => setHcaptchaPasscode("")}
                                onVerify={token => setHcaptchaPasscode(token)}
                                theme={hcaptchaTheme}
                                sitekey={HCAPTCHA_SITEKEY}
                            />
                        ) : (
                            "HCAPTCHA_SITEKEY not found"
                        )}
                    </Box>
                    <Box flexGrow={1} textAlign={"center"}>
                        <Button
                            w="100%"
                            bg={"blue.400"}
                            color={"white"}
                            _hover={{
                                bg: "blue.500",
                            }}
                            isDisabled={isLoading || !address || !hcaptchaPasscode}
                            isLoading={isLoading}
                            onClick={handleSubmit}
                        >
                            Request on Kovan
                        </Button>
                    </Box>
                </Stack>
            </Stack>
        </Box>
    )
}
