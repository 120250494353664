import { Container } from "@chakra-ui/react"
import React from "react"

import { Banner } from "./component/Banner"
import { BannerTestnet } from "./component/BannerTestnet"
import { FaucetDesign } from "./component/FaucetDesign"
import { FaucetDesignTestnet } from "./component/FaucetDesignTestnet"
import { IS_PROD } from "./util/stage"

export function App() {
    return (
        <Container maxW={"7xl"}>
            {IS_PROD ? <Banner /> : <BannerTestnet />}
            {IS_PROD ? <FaucetDesign /> : <FaucetDesignTestnet />}
        </Container>
    )
}
